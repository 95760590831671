import React, { useEffect } from "react";
import VideoBox from "../../components/AboutUs/videoBox";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import InnerHero from "../../components/Shared/innerHero";
import { useQueryParam } from "gatsby-query-params";


async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

function NsTheWar() {
    const token = useQueryParam("token", "");

    // useEffect(() => {
    //     console.log(token)
    // }, [token])
    return (
        <Layout>
            <Seo title="Share" />
            <section className="hero bg_img" data-background="/bg/hero.jpg">
                <div className="hero__shape">
                    <img src="/elements/hero/shape-2.png" alt="no surrender" />
                </div>
                <div className="el-1">
                    <img src="/elements/hero/el-1.png" alt="no surrender" />
                </div>
                <div className="el-2">
                    <img src="/elements/hero/el-2.png" alt="no surrender" />
                </div>
                <div className="el-3">
                    <img src="/elements/hero/el-3.png" alt="no surrender" />
                </div>
                <div className="el-4">
                    <img src="/elements/hero/el-4.png" alt="no surrender" />
                </div>
                <div className="el-5">
                    <img src="/elements/hero/el-5.png" alt="no surrender" />
                </div>
                <div className="el-6">
                    <img src="/elements/hero/el-6.png" alt="no surrender" />
                </div>
                <div className="el-7">
                    <img src="/elements/hero/el-7.png" alt="no surrender" />
                </div>
                <div className="el-8">
                    <img src="/elements/hero/el-8.png" alt="no surrender" />
                </div>
                <div className="el-9">
                    <img src="/elements/hero/el-9.png" alt="no surrender" />
                </div>
                <div className="el-10">
                    <img src="/elements/hero/el-10.png" alt="no surrender" />
                </div>
                <div className="el-11">
                    <img src="/elements/hero/el-11.png" alt="no surrender" />
                </div>
            </section>

            <section className="pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="hero__content d-flex flex-column align-items-center">
                                <h2
                                    className="hero__title wow fadeInUp text-center"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                                >
                                    JOIN THE WAR!
                                </h2>
                                <h3>NoSurrender: The War</h3>
                            </div>
                            <div>
                                <div className="d-flex flex-row mb-3 justify-content-center mt-3" >
                                    <button type="button" className="btn btn-link mr-3" onClick={() => {
                                        postData('https://ntf.nosurrender.studio/api/services/app/ShareLinkClient/VerifyShareLink', { token: token })
                                            .then(data => {
                                                console.log("SUCCESS", data); // JSON data parsed by `data.json()` call
                                                window.open('https://apps.apple.com/app/nosurrender-the-war/id1570618637', '_blank');
                                            }).catch(() => {
                                                window.open('https://apps.apple.com/app/nosurrender-the-war/id1570618637', '_blank');
                                            }).finally(() => {
                                                window.open('https://apps.apple.com/app/nosurrender-the-war/id1570618637', '_blank');
                                            })
                                    }}>
                                        <img
                                            src="/elements/apple-btn.png"
                                            alt="no surrender"
                                        />
                                    </button>
                                    <button type="button" className="btn btn-link" onClick={() => {
                                        postData('https://ntf.nosurrender.studio/api/services/app/ShareLinkClient/VerifyShareLink', { token: token })
                                            .then(data => {
                                                console.log("SUCCESS", data);// JSON data parsed by `data.json()` call
                                                window.open('https://play.google.com/store/apps/details?id=com.nosurrenderstudio.nosurrenderdemothewar', '_blank');
                                            }).catch(() => {
                                                window.open('https://apps.apple.com/app/nosurrender-the-war/id1570618637', '_blank');
                                            }).finally(() => {
                                                window.open('https://apps.apple.com/app/nosurrender-the-war/id1570618637', '_blank');
                                            })
                                    }}>
                                        <img
                                            src="/elements/google-btn.png"
                                            alt="no surrender"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NsTheWar